import { CdkStep } from '@angular/cdk/stepper';
import { Directive, ElementRef, Host, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[hqCompletedStep]',
    standalone: false
})
export class CompletedStepDirective implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        @Host() private stepper: MatStepper,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.stepper.selectionChange
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                const stepsUserHasStarted = this.stepper.steps.filter((step: CdkStep) => step.interacted);
                stepsUserHasStarted.forEach((step: CdkStep, index: number) => {
                    // Forces the stepper to show numbers instead of an icon
                    step.completed = false;

                    // eslint-disable-next-line no-underscore-dangle
                    const stepHeaders = this.stepper._stepHeader.toArray();
                    const headerForCurrentStep = stepHeaders[index];
                    // This is a private property and can't be directly accessed
                    const elementRefProperty = '_elementRef';
                    const headerElement: ElementRef = headerForCurrentStep[elementRefProperty];

                    if (headerElement) {
                        this.renderer.addClass(headerElement.nativeElement, 'completed-step');
                    }
                });
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
