import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Promotion } from '@hq-app/promotions-management/models/promotion';
import { routeParts } from '@hq-core/routes';

@Component({
    selector: 'hq-display-ad',
    templateUrl: './display-ad.component.html',
    styleUrls: ['./display-ad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayAdComponent implements OnInit, OnDestroy {
    @HostBinding('class.display-ad') cssClass = true;
    @Input() promotion: Promotion;
    @Input() layout: 'standard' | 'list' = 'standard';
    @Output() triggerAction = new EventEmitter<Promotion>();
    shouldBreakToColumn = false;

    routeUrl: string;
    hasExternalUrl: boolean;
    resizeObserver: ResizeObserver;

    constructor(
        private cdRef: ChangeDetectorRef,
        private host: ElementRef,
        private zone: NgZone
    ) {
    }

    ngOnInit(): void {
        const productUrl = `/${routeParts.promotions}/${this.promotion.id}/${routeParts.products}`;
        const hasExternalUrl = !!(this.promotion?.brand?.url);

        this.hasExternalUrl = hasExternalUrl;
        this.routeUrl = hasExternalUrl ? this.promotion?.brand?.url : productUrl;

        this.resizeObserver = new ResizeObserver(entries => {
            this.zone.run(() => {
                const elementWidth = entries[0]?.contentRect?.width;
                this.shouldBreakToColumn = elementWidth < 575;
                this.cdRef.detectChanges();
            });
        });

        this.resizeObserver.observe(this.host.nativeElement);
    }

    ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.host.nativeElement);
    }

    onTriggerAction(): void {
        this.triggerAction.emit(this.promotion);
    }
}
