import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'hq-product-thumbnail',
    templateUrl: './product-thumbnail.component.html',
    styleUrls: ['./product-thumbnail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductThumbnailComponent implements OnInit {
    @Input() url: string;

    hasProductImage = false;
    fullUrl: string;
    private hasLoaded = false;


    ngOnInit(): void {
        if (this.url) {
            this.fullUrl = this.url;
            this.hasProductImage = true;
        }
        this.hasLoaded = true;
    }

    onFailToLoad(event: ErrorEvent): void {
        if (this.hasLoaded) {
            this.hasProductImage = false;
        }
    }
}
