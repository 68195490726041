import { Injectable } from '@angular/core';
import { ImpersonationToken } from '@hq-core/models/impersonation-token';
import { ImpersonationTokenResource } from '@hq-core/resources/impersonation-token.resource';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthImpersonationService {
    constructor(
        private impersonationTokenResource: ImpersonationTokenResource
    ) {
    }

    getImpersonationToken(): string {
        const token = JSON.parse(localStorage.getItem('impersonationToken')) as ImpersonationToken;
        const idToken = new CognitoIdToken({ IdToken: token.idToken });
        return idToken?.getJwtToken() || '';
    }

    isImpersonating(): boolean {
        const token = localStorage.getItem('impersonationToken');
        return token !== null && token !== '' && token !== undefined;
    }

    impersonate(userId: number): Observable<boolean> {
        return this.impersonationTokenResource.get(userId).pipe(
            map(token => {
                const stringifiedToken = JSON.stringify(token);
                localStorage.setItem('impersonationToken', stringifiedToken);
                return true;
            })
        );
    }

    clearImpersonationToken(): void {
        localStorage.removeItem('impersonationToken');
    }

    isAuthenticated(): boolean {
        const expiration = this.getExpiration();
        return Date.now() < expiration;
    }

    getExpiration(): number {
        const token = JSON.parse(localStorage.getItem('impersonationToken')) as ImpersonationToken;
        const idToken = new CognitoIdToken({ IdToken: token?.idToken });
        const expiration = (idToken?.getExpiration() ?? 0) * 1000;
        return expiration;
    }

    redirectToUserEditor(): void {
        const token = JSON.parse(localStorage.getItem('impersonationToken')) as ImpersonationToken;
        const idToken = new CognitoIdToken({ IdToken: token?.idToken });
        const userId = idToken?.payload?.id;
        this.clearImpersonationToken();
        this.redirect(`/user-management/users/${userId ?? ''}`);
    }

    redirect(url: string): void {
        window.location.href = `${url}`;
    }
}
