import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'hq-titled-panel',
    templateUrl: './titled-panel.component.html',
    styleUrls: ['./titled-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TitledPanelComponent {
    @Input() panelTitle: string;
}
