import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultFormChangeDebounceTime } from '@hq-core/models/forms';
import { TextInputDialogData } from '@hq-shared/models/text-input-dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-text-input-dialog',
    templateUrl: './text-input-dialog.component.html',
    styleUrls: ['./text-input-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextInputDialogComponent implements OnInit, OnDestroy {
    formControl: FormControl;
    title: string;
    content: string;
    actionButtonText: string;
    placeholder: string;
    characterLabel: string;

    minLength: number;
    maxLength: number;

    isDisabled: boolean;
    isSingleLine: boolean;
    hasMinLength: boolean;
    hasMaxLength: boolean;
    shouldDisplayHint: boolean;

    private readonly unsubscribe = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: TextInputDialogData,
        private dialogRef: MatDialogRef<TextInputDialogComponent>,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        const validators = this.buildValidators(this.data);

        this.title = this.data.title || 'Perform Action';
        this.content = this.data.content || '';
        this.actionButtonText = this.data.actionButtonText || 'Submit';
        this.placeholder = this.data.placeholder || '';
        this.characterLabel = this.data.minLength === 1 ? 'character' : 'characters';

        this.minLength = this.data.minLength;
        this.maxLength = this.data.maxLength;

        this.isSingleLine = !(this.data.isMultiLine);
        this.hasMaxLength = !!(this.data.maxLength);
        this.hasMinLength = !!(this.data.minLength);
        this.shouldDisplayHint = this.hasMaxLength || this.hasMinLength;

        this.formControl = new FormControl(
            {
                value: this.data.initialValue || '',
                disabled: false
            },
            {
                validators: validators.concat(this.data.validators || [])
            }
        );

        this.formControl.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe(value => {
                this.isDisabled = !this.formControl.valid;
                this.cdRef.detectChanges();
            });

        this.isDisabled = !this.formControl.valid;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onTriggerAction(): void {
        if (this.formControl.valid) {
            this.isDisabled = true;
            this.dialogRef.close(this.formControl.value);
        }
    }

    private buildValidators(data: TextInputDialogData): Array<ValidatorFn> {
        const validators = [];
        if (data.minLength) {
            validators.push(Validators.minLength(data.minLength));
        }

        if (data.maxLength) {
            validators.push(Validators.maxLength(data.maxLength));
        }

        if (data.isRequired) {
            validators.push(Validators.required);
        }

        return validators.concat(data.validators || []);
    }
}
