import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { defaultFormChangeDebounceTime } from '@hq-core/models/forms';
import { alphaNumericSpacesRegex, VendorForm, VendorFormChangeEvent } from '@hq-shared/models/vendor-form';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-vendor-form',
    templateUrl: './vendor-form.component.html',
    styleUrls: ['./vendor-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VendorFormComponent implements OnInit, OnDestroy {
    @Input() vendor: VendorForm;
    @Output() changeValue = new EventEmitter<VendorFormChangeEvent>();

    hasExternalId: boolean;
    formGroup: FormGroup;

    private readonly unsubscribe = new Subject<void>();

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.hasExternalId = true;

        this.formGroup = this.formBuilder.group({
            name: [this.vendor.name || '', Validators.required],
            externalVendorId: [this.vendor.externalVendorId || '', [
                Validators.required,
                Validators.pattern(alphaNumericSpacesRegex)
            ]],
            contact: [this.vendor.contact || ''],
            email: [this.vendor.email || '', [
                Validators.required,
                Validators.email
            ]],
            emailCC: [
                this.vendor.emailCC || '', [
                    Validators.email
                ]
            ],
            address1: [this.vendor.address1 || '', Validators.required],
            address2: [this.vendor.address2 || ''],
            city: [this.vendor.city || '', Validators.required],
            state: [this.vendor.state || '', Validators.required],
            zip: [this.vendor.zip || '', Validators.required],
            phone: [this.vendor.phone || ''],
            minimumOrderAmount: [this.vendor.minimumOrderAmount, Validators.min(0)],
            freeShippingAmount: [this.vendor.freeShippingAmount, Validators.min(0)]
        });

        if (this.vendor.isLocal) {
            this.hasExternalId = false;
            this.formGroup.removeControl('externalVendorId');
        }

        if (!(this.vendor.isLocal || this.vendor.privateCatalog)) {
            this.formGroup.disable();
            this.formGroup.controls.externalVendorId.enable();
        }

        this.formGroup.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe(value => {
                const updatedVendor = this.mapToVendor(value);
                const event = new VendorFormChangeEvent({
                    isValid: this.formGroup.valid,
                    vendor: updatedVendor
                });

                this.changeValue.emit(event);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /* Applies the changed values of the form to the current vendor */
    private mapToVendor(formValue: any): VendorForm {
        return new VendorForm({
            ...this.vendor,
            ...formValue,
            externalVendorId: (formValue.externalVendorId || '').trim()
        });
    }
}
