import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'hq-callout',
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalloutComponent {
    @Input() icon: string;
    @Input() title: string;
    @Input() message: string;
}
