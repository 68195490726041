import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'hq-order-total-status',
    templateUrl: './order-total-status.component.html',
    styleUrls: ['./order-total-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrderTotalStatusComponent implements OnInit {
    @Input() itemCount: number;
    @Input() statusDisplay: string;
    @Input() total: number;

    itemTotal: string;

    ngOnInit() {
        this.itemTotal = `${this.itemCount || 0} ${this.itemCount === 1 ? 'Item' : 'Items'}`;
    }
}
