import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormChangeEvent } from '@hq-core/models/forms';
import { DropdownOption, filterByDisplayName } from '@hq-shared/models/dropdown-option';
import { TypeIdentity } from '@hq-shared/type-identity/type-identity';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'hq-autocomplete-multi-select',
    templateUrl: './autocomplete-multi-select.component.html',
    styleUrls: ['./autocomplete-multi-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutocompleteMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: Array<DropdownOption> = [];
    @Input() placeholder: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() selectedOptionsOverride: Array<DropdownOption>;
    @Output() markAsInitialized = new EventEmitter<AbstractControl>();
    @Output() changeValue = new EventEmitter<FormChangeEvent<Array<DropdownOption>>>();
    @Output() changeText = new EventEmitter<string>();

    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('autoTrigger', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    formControl = new FormControl();
    formArray = new FormArray([]);
    filteredOptions: Array<DropdownOption>;

    private unsubscribe = new Subject<void>();

    constructor(
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.filteredOptions = this.options || [];
        this.markAsInitialized.emit(this.formArray);

        this.formControl.valueChanges
            .pipe(
                tap(value => this.changeText.emit(value)),
                map((input: DropdownOption | null) => {
                    return input ? this.filterOptions(input) : this.options.slice();
                }),
                map((options: Array<DropdownOption>) => {
                    return this.filterAlreadyAddedOptions(options);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(options => {
                this.filteredOptions = options;
                this.cdRef.detectChanges();
            });

        this.formArray.valueChanges.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(value => {
                const event = new FormChangeEvent({ value });
                this.changeValue.emit(event);
            });

        this.setOverrideSelection();
        this.toggleEditing();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.firstChange) {
            this.filteredOptions = this.filterAlreadyAddedOptions(this.options || []);
            this.cdRef.detectChanges();
        }

        if (changes.selectedOptionsOverride && !changes.selectedOptionsOverride.firstChange) {
            this.setOverrideSelection();
            this.toggleEditing();
        }

        if (changes.disabled && !changes.disabled.firstChange) {
            this.toggleEditing();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    removeChip(index: number): void {
        this.formArray.removeAt(index);
        // Pushes a value change event to the form control repopulating the removed option.
        // Otherwise, the autocomplete options only populate when the user types into the input.
        this.formControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
        });
    }

    optionSelected(event: MatAutocompleteSelectedEvent): void {
        this.formArray.push(
            new FormControl(event.option.value)
        );
        this.input.nativeElement.value = '';
        this.formControl.setValue(null);
    }

    onClickTrigger(): void {
        this.autoTrigger.openPanel();
    }

    private filterOptions(input: any): Array<DropdownOption> {
        const nameFilter = TypeIdentity.isString(input) ? input?.trim() : input?.displayName;
        return filterByDisplayName(this.options, nameFilter);
    }

    private setOverrideSelection(): void {
        if (this.selectedOptionsOverride?.length !== this.formArray.length) {
            const selectedOptions = this.selectedOptionsOverride || [];
            const formArrayValues: Array<DropdownOption> = this.formArray.value;

            formArrayValues.forEach(v => {
                const shouldRemove = !selectedOptions.some(option => option.id === v.id);
                if (shouldRemove) {
                    const index = this.formArray.value.find(value => value.id === v.id);
                    this.formArray.removeAt(index);
                }
            });

            selectedOptions.forEach(option => {
                const shouldAddChip = !formArrayValues.some(v => v.id === option.id);
                if (shouldAddChip) {
                    this.formArray.push(new FormControl(option));
                }
            });
        }
    }

    private toggleEditing(): void {
        if (this.disabled) {
            this.formControl.disable({ emitEvent: false });
            this.formArray.disable({ emitEvent: false });
            this.cdRef.detectChanges();
        }
    }

    private filterAlreadyAddedOptions(options: Array<DropdownOption>): Array<DropdownOption> {
        return options.filter(option => {
            return !this.formArray.value.some(v => v.id === option.id);
        });
    }
}
