import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '@hq-core/messages/message.service';
import { Message, MessageParameters } from '@hq-core/models/messages';
import { UserService } from '@hq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-system-messages',
    templateUrl: './system-messages.component.html',
    styleUrls: ['./system-messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SystemMessagesComponent implements OnInit, OnDestroy {
    messages: Array<Message>;
    hasMessages: boolean;

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private domSanitizer: DomSanitizer,
        private messageService: MessageService,
        private userService: UserService,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        const searchState = new SearchState({
            sortBy: 'startDate',
            sortDirection: 'desc'
        });

        this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const messageParameters = new MessageParameters({
                        elevated: true,
                        startDate: new Date(),
                        endDate: new Date(),
                        destination: this.messageService.getDestinationForUserGroup(user.userGroup)
                    });

                    return this.messageService.getMessages(searchState, messageParameters);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(response => {
                const sanitizedMessages = (response.items ?? []).map(message => {
                    message.sanitizedBody = this.domSanitizer.bypassSecurityTrustHtml(message.body);
                    return message;
                });

                this.messages = sanitizedMessages;
                this.hasMessages = sanitizedMessages.length > 0;
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
