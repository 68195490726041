import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'hq-drop-zone',
    templateUrl: './drop-zone.component.html',
    styleUrls: ['./drop-zone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DropZoneComponent {
    @ViewChild('fileInput') fileInput: ElementRef;
    @Input() fileRequirements: Array<string>;
    @Output() changeFiles = new EventEmitter<FileList>();

    onDropFile(files: FileList): void {
        this.changeFiles.emit(files);
    }

    onSelectFile(event: Event): void {
        const input = event.target as HTMLInputElement;
        const files = input.files;

        if (files.length > 0) {
            this.changeFiles.emit(files);
            this.fileInput.nativeElement.value = '';
        }
    }
}
