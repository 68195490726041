import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ApiSortDirection } from '../../api-interaction';
import { FilterGroup, FilterMenuChangeEvent, FilterOption, FilterOptionId, SortOption } from '../filter-menu';
import { Subject } from 'rxjs';

@Component({
    selector: 'gc-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterMenuComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * @description This value will only be recognized when the component initializes. This is useful for restoring a cached state;
     * otherwise, configure the sort options using the sortOptions input.
     */
    @Input() initialSortDirection: ApiSortDirection;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() sortOptions = new Array<SortOption>();
    @Output() changeSort = new EventEmitter<FilterMenuChangeEvent>();
    @Output() changeFilters = new EventEmitter<FilterMenuChangeEvent>();
    @Output() clearFilters = new EventEmitter<FilterMenuChangeEvent>();

    hasSortOptions = false;
    hasFilters = false;

    readonly panelHeaderHeight = '48px';
    readonly filterOptionPixelHeight = 48;
    readonly filterScrollMinBuffer = this.filterOptionPixelHeight * 6;
    readonly filterScrollMaxBuffer = this.filterOptionPixelHeight * 8;

    private selectedSortOption = new SortOption();
    private selectedFilters = new Map<string, Array<FilterOption>>();

    private readonly maxFiltersToDisplayPerGroup = 6;
    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.hasSortOptions = this.sortOptions?.length > 0;
        if (this.hasSortOptions) {
            const selectedSortOption = this.getSelectedSortOption(this.sortOptions) ?? this.getDefaultSortOption(this.sortOptions);
            selectedSortOption.isSelected = true;
            this.selectedSortOption = selectedSortOption;
        }

        this.hasFilters = this.filterGroups?.length > 0;
        if (this.hasFilters) {
            this.initializeFilterGroups(this.filterGroups);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterGroups && !changes.filterGroups.firstChange) {
            this.hasFilters = this.filterGroups?.length > 0;
            if (this.hasFilters) {
                this.initializeFilterGroups(this.filterGroups);
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onChangeFilter(event: MatSelectionListChange, groupId: string): void {
        const changedOption = event.options[0];

        const groupFilters = this.filterGroups.find(fg => fg.id === groupId)?.filters ?? [];
        const changedFilter = groupFilters.find(f => f.id === changedOption.value.id) ?? new FilterOption();
        changedFilter.isSelected = changedOption.selected;

        this.filterGroups.forEach(group => {
            const selectedFilters = group.filters.filter(f => f.isSelected);
            this.selectedFilters.set(group.id, selectedFilters);
        });

        const changeEvent = new FilterMenuChangeEvent({
            selectedFilters: this.selectedFilters
        });
        this.changeFilters.emit(changeEvent);
    }

    onClearFilters(): void {
        this.filterGroups.forEach(group => {
            this.selectedFilters.set(group.id, []);
            group.filters.forEach(f => f.isSelected = false);
        });

        this.selectedSortOption.isSelected = false;
        // Force change detection so the selected option will be marked as not selected
        this.cdRef.detectChanges();

        this.selectedSortOption = this.getDefaultSortOption(this.sortOptions);
        this.selectedSortOption.isSelected = true;

        const event = new FilterMenuChangeEvent({
            sortOption: this.selectedSortOption,
            direction: this.selectedSortOption.defaultDirection,
            selectedFilters: this.selectedFilters
        });
        this.clearFilters.emit(event);
    }

    onSelectSortOption(event: FilterMenuChangeEvent): void {
        const nextSelectedOption = event.sortOption;

        if (this.selectedSortOption.id !== nextSelectedOption.id) {
            this.selectedSortOption.isSelected = false;
            this.selectedSortOption = nextSelectedOption;
            this.selectedSortOption.isSelected = true;
        }

        this.changeSort.emit(event);
    }

    filterTrackBy(index: number, filter: FilterOption): FilterOptionId {
        return filter.id;
    }

    filterGroupTrackBy(index: number, group: FilterGroup): string {
        return group.id;
    }

    isSameFilter(filter1: FilterOption, filter2: FilterOption): boolean {
        return filter1.id === filter2.id;
    }

    private getDefaultSortOption(sortOptions: Array<SortOption>): SortOption {
        const options = (sortOptions ?? []);
        return options.find(so => so.isDefaultOption) ?? options[0] ?? new SortOption();
    }

    private getSelectedSortOption(sortOptions: Array<SortOption>): SortOption {
        return (sortOptions ?? []).find(so => so.isSelected);
    }

    private calculateFilterGroupHeight(totalFiltersInGroup: number): number {
        const numberOfFiltersToDisplay = Math.min(totalFiltersInGroup, this.maxFiltersToDisplayPerGroup);
        return numberOfFiltersToDisplay * this.filterOptionPixelHeight;
    }

    private initializeFilterGroups(filterGroups: Array<FilterGroup>): void {
        filterGroups.forEach(filterGroup => {
            // Virtual scroll requires a defined height to display, so we need to calculate this for each filter group
            filterGroup.pixelHeight = this.calculateFilterGroupHeight(filterGroup.filters.length);

            const selectedFilters = filterGroup.filters.filter(filter => filter.isSelected);
            this.selectedFilters.set(filterGroup.id, selectedFilters);
        });
    }
}
