import { CurrencyPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ThemeService } from '@hq-core/theme/theme.service';
import { TextTransformFn } from '@hq-shared/models/chart';
import Chart, { ChartPoint, TimeUnit } from 'chart.js';
import moment from 'moment';

@Component({
    selector: 'hq-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LineChartComponent implements OnChanges {
    @Input() chartPoints: Array<ChartPoint>;
    @Input() timeScale: TimeUnit;
    @Input() tooltipValueFormatter: TextTransformFn = (value: string) => `${this.currencyPipe.transform(value || 0)}`;
    @ViewChild('canvas') canvas: ElementRef;
    chart: Chart;

    constructor(
        private themeService: ThemeService,
        private currencyPipe: CurrencyPipe,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartPoints && !changes.chartPoints.firstChange) {
            const lineColor = this.themeService.getAccentColor();
            const fillColor = this.themeService.getAccentColorMuted();

            this.chart = new Chart(this.canvas.nativeElement, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: [{
                        spanGaps: true,
                        borderColor: lineColor,
                        backgroundColor: fillColor,
                        pointBackgroundColor: lineColor,
                        pointHoverBackgroundColor: lineColor,
                        pointHoverBorderColor: lineColor,
                        data: this.chartPoints
                    }]
                },
                options: {
                    layout: {
                        padding: 8
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: { display: false },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            label: tooltipItem => {
                                const isDaily = this.timeScale === 'day';
                                const date = isDaily
                                    ? moment(new Date(tooltipItem.label)).format('M/D')
                                    : moment(new Date(tooltipItem.label)).format('MMM');
                                return `${this.tooltipValueFormatter(tooltipItem.value)} - ${date}`;
                            },
                            title: () => null
                        }
                    },
                    hover: {
                        mode: 'index',
                        intersect: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                callback: () => '',
                                backdropColor: 'rgba(255,255,255,0)',
                            },
                            gridLines: {
                                color: '#C7C7C7',
                                drawOnChartArea: false,
                                drawTicks: false,
                                z: 2
                            },
                            type: 'time',
                            time: { unit: this.timeScale }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: '#C7C7C7',
                                drawOnChartArea: false,
                                drawTicks: false
                            },
                            ticks: {
                                callback: () => '',
                                backdropColor: 'rgba(255,255,255,0)',
                            }
                        }]
                    }
                }
            });

            this.cdRef.detectChanges();
        }
    }
}
