import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TextFilterChangeEvent } from '@cia-front-end-apps/shared/api-interaction';
import { SortOption } from '@cia-front-end-apps/shared/filter-menu';
import { FilterGroup } from '@cia-front-end-apps/shared/filter-menu';
import { FilterMenuChangeEvent } from '@cia-front-end-apps/shared/filter-menu';

@Component({
    selector: 'hq-order-toolbar',
    templateUrl: './order-toolbar.component.html',
    styleUrls: ['./order-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrderToolbarComponent {
    @Output() clearFilters = new EventEmitter<FilterMenuChangeEvent>();
    @Output() changeFilters = new EventEmitter<FilterMenuChangeEvent>();
    @Output() changeTextFilter = new EventEmitter<TextFilterChangeEvent>();
    @Output() changeSortOption = new EventEmitter<FilterMenuChangeEvent>();

    @Input() sortOptions: Array<SortOption>;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() textOverride: string;
    @Input() canUseFilter: boolean;
    @Input() filterDisplayText: string;

    onChangeSortOption(event: FilterMenuChangeEvent): void {
        this.changeSortOption.emit(event);
    }

    onClearFilters(event: FilterMenuChangeEvent): void {
        this.clearFilters.emit(event);
    }

    onChangeFilters(event: FilterMenuChangeEvent): void {
        this.changeFilters.emit(event);
    }

    onChangeTextFilter(event: TextFilterChangeEvent): void {
        this.changeTextFilter.emit(event);
    }
}
