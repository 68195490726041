import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LineItemChangeEvent } from '@hq-app/vendor-ordering/models/acknowledgement';
import { FormsService } from '@hq-core/forms/forms.service';
import { wholeNumberRegex } from '@hq-core/models/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-quantity-field',
    templateUrl: './quantity-field.component.html',
    styleUrls: ['./quantity-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuantityFieldComponent implements OnInit, OnDestroy, OnChanges {
    @Input() disabled = false;
    @Input() isRejected = false;
    @Input() quantity: number;
    @Input() unitPrice: number;
    @Input() max: number;
    @Input() parentFormGroup: FormGroup;
    @Output() changeValue = new EventEmitter<LineItemChangeEvent>();

    totalPrice: number;
    hasError: boolean;
    min = 0;
    formControl: FormControl;

    private previousValue: number;
    private readonly unsubscribe = new Subject<void>();

    constructor(private formsService: FormsService) { }

    ngOnInit() {
        const validators = [
            Validators.pattern(wholeNumberRegex),
            Validators.min(this.min),
            Validators.required
        ];
        if (this.max) {
            validators.push(Validators.max(this.max));
        }

        this.formControl = new FormControl(
            {
                value: this.quantity || 0,
                disabled: this.disabled
            },
            {
                updateOn: 'blur',
                validators: validators
            }
        );
        this.previousValue = this.quantity;
        this.hasError = Object.keys(this.formControl.errors || {}).length > 0;

        if (this.parentFormGroup) {
            this.parentFormGroup.setControl('qty', this.formControl);
        }

        this.formControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe((value: number) => {
                if (!this.formsService.hasValueBeenSet(value)) {
                    this.updateDisplayValue(this.previousValue);
                    return;
                }

                this.previousValue = value;

                // This will update the display value in the case of inputs with leading zeros.
                // We don't emit events to avoid an endless loop
                this.updateDisplayValue(value);
                this.hasError = Object.keys(this.formControl.errors || {}).length > 0;
                this.updateTotalPriceDisplay(value);

                const event = new LineItemChangeEvent({
                    isValid: this.formControl.disabled || this.formControl.valid,
                    qty: value
                });

                this.changeValue.emit(event);
            });

        this.updateTotalPriceDisplay(this.quantity);
        if (this.disabled) {
            this.formControl.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled && !changes.disabled.firstChange) {
            if (this.formControl && this.disabled) {
                this.formControl.disable();
            }
        }

        if (changes.quantity && !changes.quantity.firstChange) {
            this.updateDisplayValue(this.quantity);
            this.updateTotalPriceDisplay(this.quantity);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private updateTotalPriceDisplay(quantity: number): void {
        if (this.unitPrice) {
            this.totalPrice = quantity * this.unitPrice;
        }
    }

    private updateDisplayValue(value: number): void {
        this.formControl.setValue(value, {
            emitEvent: false,
            emitViewToModelChange: false,
            onlySelf: true
        });
    }
}
