import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import * as marked from 'marked';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'markdown',
    standalone: false
})
export class MarkdownPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer){ }

    transform(value: any): string {
        if (value && value.length > 0) {
            const md = marked.setOptions({});
            const html = md.parse(value);
            return this.sanitizer.sanitize(SecurityContext.HTML, html);
        }
        return value;
    }

}
