import { CurrencyPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ThemeService } from '@hq-core/theme/theme.service';
import { Bar } from '@hq-shared/models/chart';
import { Chart } from 'chart.js';

@Component({
    selector: 'hq-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BarChartComponent implements OnChanges {
    @Input() bars: Array<Bar>;
    @ViewChild('canvas') canvas: ElementRef;

    chart: Chart;

    constructor(
        private themeService: ThemeService,
        private currencyPipe: CurrencyPipe,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bars && !changes.bars.firstChange) {
            const barColor = this.themeService.getAccentColor();

            this.chart = new Chart(this.canvas.nativeElement, {
                type: 'bar',
                data: {
                    labels: this.bars.map(b => b.label),
                    datasets: [{
                        backgroundColor: barColor,
                        hoverBackgroundColor: barColor,
                        data: this.bars.map(b => b.value)
                    }]
                },
                options: {
                    layout: {
                        padding: 8
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: { display: false },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            label: tooltipItem => {
                                return `${this.currencyPipe.transform(tooltipItem.value || 0)}`;
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: '#C7C7C7',
                                drawOnChartArea: false,
                                drawTicks: false,
                                drawBorder: false
                            },
                            ticks: {
                                backdropColor: 'rgba(255,255,255,0)',
                                padding: 8
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 8,
                                callback: (value: number) => {
                                    return `${this.currencyPipe.transform(value || 0)}`;
                                }
                            }
                        }]
                    }
                }
            });

            this.cdRef.detectChanges();
        }
    }
}
