import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'hq-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotFoundComponent { }
