import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'evaluateOnChange',
    standalone: false
})
export class EvaluateOnChangePipe implements PipeTransform {

    /**
     * Accepts a function reference and a value passed from the template to the function.
     * This allows for functions to be called in templates only when the input changes rather
     * than any time something changes on the component.
     */
    transform(input: any, fn: (input: any, ...args: any[]) => any, ...args: any[]): any {
        return fn(input, ...args);
    }
}
