import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'hq-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UnauthorizedComponent { }
