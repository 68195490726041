import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsKey',
    standalone: false
})
export class ContainsKeyPipe implements PipeTransform {

    transform(collection: Array<any>, item: any): boolean {
        return (collection ?? []).includes(item);
    }
}
