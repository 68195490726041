import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter, Input, OnChanges, OnDestroy, OnInit,
    Output, QueryList, SimpleChanges,
    ViewChild, ViewChildren
} from '@angular/core';
import { DropdownOption, filterByDisplayName } from '@hq-shared/models/dropdown-option';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { FormChangeEvent } from '@hq-core/models/forms';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TypeIdentity } from '@hq-shared/type-identity/type-identity';
import {  MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { MatChip } from '@angular/material/chips';
import { MatSelectChange as MatSelectionChange } from '@angular/material/select';

export type MaxDisplayItems = number | 'unlimited';

@Component({
    selector: 'hq-autocomplete-multiselect-v2',
    templateUrl: './autocomplete-multiselect-v2.component.html',
    styleUrls: ['./autocomplete-multiselect-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutocompleteMultiselectV2Component implements OnInit, OnChanges, OnDestroy {
    @Input() options: Array<DropdownOption> = [];
    @Input() placeholder: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() selectedOptionsOverride: Array<DropdownOption>;
    @Input() searching = false;
    @Input() resetControl = new Subject<void>();
    @Input() maxDisplayItems: MaxDisplayItems = 'unlimited';
    @Output() markAsInitialized = new EventEmitter<AbstractControl>();
    @Output() changeValue = new EventEmitter<FormChangeEvent<Array<DropdownOption>>>();
    @Output() changeText = new EventEmitter<string>();

    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('select') select: MatSelect;
    @ViewChildren(MatChip) chips: QueryList<MatChip>;

    selectControl = new FormControl();
    formControl = new FormControl();
    formArray = new FormArray([]);
    filteredOptions: Array<DropdownOption>;
    displayedOptions: Array<DropdownOption>;
    hasMoreItems = false;

    private unsubscribe = new Subject<void>();

    constructor(
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.filteredOptions = this.options || [];
        this.updateDisplayedOptions();
        this.markAsInitialized.emit(this.formArray);

        this.formControl.valueChanges
            .pipe(
                tap(value => this.changeText.emit(value)),
                map((input: DropdownOption | null) => {
                    return input ? this.filterOptions(input) : this.options.slice();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(options => {
                this.filteredOptions = options;
                this.updateDisplayedOptions();
                this.cdRef.detectChanges();
            });

        this.formArray.valueChanges.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(value => {
                const event = new FormChangeEvent({ value });
                this.changeValue.emit(event);
            });


        this.resetControl
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.formArray.clear();
                this.filteredOptions.forEach(option => option.selected = false);
                this.selectControl.setValue(null);
                this.formControl.setValue('');
            });

        this.setOverrideSelection();
        this.toggleEditing();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.firstChange) {
            const filteredOptions = this.filterOptions(this.formControl?.value ?? '');
            this.filteredOptions = filteredOptions || [];
            this.updateDisplayedOptions();
            this.cdRef.detectChanges();
        }

        if (changes.selectedOptionsOverride && !changes.selectedOptionsOverride.firstChange) {
            this.setOverrideSelection();
            this.toggleEditing();
        }

        if (changes.disabled && !changes.disabled.firstChange) {
            this.toggleEditing();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    clearSearch(event: MouseEvent) {
        event.stopPropagation();
        this.formControl.setValue('');
    }

    clearSelections(event: MouseEvent) {
        event.stopPropagation();
        this.formArray.clear();
        this.filteredOptions.forEach(option => option.selected = false);
    }

    onRemoveFromMenu(option: DropdownOption): void {
        requestAnimationFrame(() => {
            if (this.formArray.length > 3) {
                this.menuTrigger.openMenu();
            }
            this.removeChip(this.formArray.value.findIndex(i => i.id === option.id), option);
        })
    }

    removeChip(index: number, option: DropdownOption): void {
        const filterOption = this.filteredOptions.find(o => o.id === option.id)
        if (filterOption) {
            option.selected = false;
        }
        this.formArray.removeAt(index);
        // Pushes a value change event to the form control repopulating the removed option.
        // Otherwise, the autocomplete options only populate when the user types into the input.
        this.formControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
        });
    }

    optionClicked(event: MouseEvent, option: DropdownOption): void {
        event.stopPropagation();
        this.toggleSelection(option);
    }

    onSelectionChange(event: MatSelectionChange) {
        this.toggleSelection(event.source.value);
        this.selectControl.setValue(null);
    }

    toggleSelection(option: DropdownOption) {
        const index = this.formArray.value.findIndex(v => v.id === option.id);
        if (index > -1) {
            this.formArray.removeAt(index);
        } else {
            this.formArray.push(
                new FormControl(option)
            );
        }
    }

    isChecked(option: DropdownOption): boolean {
        return this.formArray.value.some(v => v.id === option.id);
    }

    getRemainingItemsCount(): number {
        if (this.maxDisplayItems === 'unlimited' || !this.hasMoreItems) {
            return 0;
        }
        return this.filteredOptions.length - (this.maxDisplayItems as number);
    }

    private updateDisplayedOptions(): void {
        if (this.maxDisplayItems === 'unlimited') {
            this.displayedOptions = this.filteredOptions;
            this.hasMoreItems = false;
            return;
        }

        if (this.filteredOptions.length > this.maxDisplayItems) {
            this.displayedOptions = this.filteredOptions.slice(0, this.maxDisplayItems);
            this.hasMoreItems = true;
        } else {
            this.displayedOptions = this.filteredOptions;
            this.hasMoreItems = false;
        }
    }

    private filterOptions(input: any): Array<DropdownOption> {
        const nameFilter = TypeIdentity.isString(input) ? input?.trim() : input?.displayName;
        return filterByDisplayName(this.options, nameFilter);
    }

    private setOverrideSelection(): void {
        if (this.selectedOptionsOverride?.length !== this.formArray.length) {
            const selectedOptions = this.selectedOptionsOverride || [];
            const formArrayValues: Array<DropdownOption> = this.formArray.value;

            formArrayValues.forEach(v => {
                const shouldRemove = !selectedOptions.some(option => option.id === v.id);
                if (shouldRemove) {
                    const index = this.formArray.value.find(value => value.id === v.id);
                    this.formArray.removeAt(index);
                }
            });

            selectedOptions.forEach(option => {
                const shouldAddChip = !formArrayValues.some(v => v.id === option.id);
                if (shouldAddChip) {
                    this.formArray.push(new FormControl(option));
                }
            });
        }
    }

    private toggleEditing(): void {
        if (this.disabled) {
            this.formControl.disable({ emitEvent: false });
            this.formArray.disable({ emitEvent: false });
            this.selectControl.disable({ emitEvent: false });
            this.cdRef.detectChanges();
        }
    }
}
