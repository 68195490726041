import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { defaultFormChangeDebounceTime } from '@hq-core/models/forms';
import { UpcCorrectionSetting } from '@hq-shared/models/upc-correction';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-upc-correction',
    templateUrl: './upc-correction.component.html',
    styleUrls: ['./upc-correction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UpcCorrectionComponent implements OnInit, OnDestroy {
    @Output() upcCorrectionChange = new EventEmitter<UpcCorrectionSetting>();

    formGroup: FormGroup;
    trimOptions: Array<number> = Array.from(Array(10).keys());
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            calculateCheckDigit: false,
            totalCharactersToTrim: 0
        });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                const event = new UpcCorrectionSetting({
                    calculateCheckDigit: this.calculateCheckDigit.value || false,
                    totalCharactersToTrim: this.totalCharactersToTrim.value || 0,
                });
                this.upcCorrectionChange.emit(event);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get calculateCheckDigit(): AbstractControl {
        return this.formGroup.get('calculateCheckDigit');
    }

    get totalCharactersToTrim(): AbstractControl {
        return this.formGroup.get('totalCharactersToTrim');
    }
}
