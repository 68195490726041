import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { StatusCardColor } from '@hq-shared/models/status-card';

@Component({
    selector: 'hq-status-multiple-card',
    templateUrl: './status-card-multiple.component.html',
    styleUrls: ['./status-card-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StatusCardMultipleComponent implements OnInit, OnChanges {
    @HostBinding('class.status-card') statusCardClass = true;
    @HostBinding('class.actionable') actionableClass: boolean;
    @Input() caption1: string;
    @Input() icon: string;
    @Input() items1: number | string = '';
    @Input() color1: StatusCardColor;
    @Input() subtext: string;
    @Input() canHover: boolean;
    @Input() isActionable: boolean;
    @Input() items2: number | string = '';
    @Input() color2: StatusCardColor;
    @Input() caption2: string;
    @Input() items3: number | string = '';
    @Input() color3: StatusCardColor;
    @Input() caption3: string;
    @Output() clickCard = new EventEmitter<string>();

    section1ColorClass: string;
    section2ColorClass: string;
    section3ColorClass: string;

    ngOnInit(): void {
        this.actionableClass = this.isActionable;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.color1) {
            this.section1ColorClass = this.color1
                ? `background-${this.color1}`
                : '';
        }
        if (changes.color2) {
            this.section2ColorClass = this.color2
                ? `background-${this.color2}`
                : '';
        }
        if (changes.color3) {
            this.section3ColorClass = this.color3
                ? `background-${this.color3}`
                : '';
        }
    }

    onClickCard(section: string): void {
        switch (section) {
            case 'Queued': {
                this.clickCard.emit('queued1');
                break;
            }
            case 'Queued\u00A0>\u00A05\u00A0Min': {
                this.clickCard.emit('queued2');
                break;
            }
            case 'Queued\u00A0>\u00A010\u00A0Min': {
                this.clickCard.emit('queued3');
                break;
            }
            case 'Posted\u00A0for\u00A0Submission': {
                this.clickCard.emit('posted1');
                break;
            }
            case 'Posted\u00A0for\u00A0Submission\u00A0>Ack.\u00A0Delay': {
                this.clickCard.emit('posted2');
                break;
            }
        }
    }
}
