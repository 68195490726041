<mat-card>
    <mat-card-content>
        <hq-empty-state heading="Looks like you don't have access to that page"
                        subheading="If this is a mistake, get in touch, and we'll help"
                        icon="lock">
        </hq-empty-state>

        <div class="contact-container">
        <span class="contact-info">
            <mat-icon>mail_outline</mat-icon>
            <a class="email" href="mailto:customerservice@geniuscentral.com">
                customerservice&#64;geniuscentral.com
            </a>
        </span>
            <span class="contact-info">
            <mat-icon>phone</mat-icon>
            1-800-360-2231
        </span>
        </div>
    </mat-card-content>
</mat-card>
