import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { DefaultValueAccessor, FormControlName } from '@angular/forms';
import { nonDigitsOnly } from '@hq-shared/phone-mask/phone-mask';
import { PhoneMaskPipe } from '@hq-shared/phone-mask/phone-mask.pipe';

@Directive({
    selector: '[hqPhoneMask]',
    standalone: false
})
export class PhoneMaskDirective implements OnInit, OnChanges {
    @Input() shouldMask = true;

    constructor(
        public formControlName: FormControlName,
        private phoneMaskPipe: PhoneMaskPipe,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        if (this.shouldMask) {
            this.maskValues();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const shouldMask = changes.shouldMask && !changes.shouldMask.firstChange && changes.shouldMask.currentValue;
        if (shouldMask) {
            this.maskValues();
        }
    }

    private maskValues(): void {
        const defaultValueAccessor = this.formControlName.valueAccessor as DefaultValueAccessor;
        defaultValueAccessor.writeValue = (value: any) => {
            const maskedValue = this.phoneMaskPipe.transform(value);
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', maskedValue);
        };

        // we don't want to save the masked value on the form
        defaultValueAccessor.onChange = (value: any) => {
            const unmaskedValue = this.unmaskValue(value);
            if (this.formControlName.control) {
                this.formControlName.control.setValue(unmaskedValue);
            }
        };

        const initialMaskedValue = this.phoneMaskPipe.transform(this.formControlName.value || '');
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', initialMaskedValue);
    }

    private unmaskValue(value: string): string {
        const maskedVal = this.phoneMaskPipe.transform(value);
        const unmaskedVal = maskedVal.replace(nonDigitsOnly, '');
        return maskedVal ? unmaskedVal : '';
    }
}
