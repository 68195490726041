import { Pipe, PipeTransform } from '@angular/core';
import { nonDigitsOnly } from '@hq-shared/phone-mask/phone-mask';

@Pipe({
    name: 'phoneMask',
    standalone: false
})
export class PhoneMaskPipe implements PipeTransform {
    transform(value: any): any {
        const currentValue = value.replace(nonDigitsOnly, '');
        if (currentValue.length < 4) {
            return currentValue;
        }

        if (currentValue.length < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        }

        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
}
